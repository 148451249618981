import React, { useEffect, useState, useMemo } from "react";
import { makeStyles } from "@mui/styles";
import Page from "../../components/Page";
import {
  Container,
  ButtonBase,
  Grid,
  CircularProgress,
  Typography,
} from "@mui/material";
import MainCard from "../../components/MainCard";

import { validateObjectData } from "../../utils";
import TreeGraphHC from "../../components/Charts/TreeGraphHC";

import GUIDELINE_DATA from "../../mock-adapter/CCF/guideline.json";
import CustomAutoComplete from "../../components/CustomAutoComplete";

import axios from "axios";
import Api from "../../components/Api";

const parentNodes = [
  "ESRS 1",
  "ESRS 2",
  "ESRS E1",
  "ESRS E2",
  "ESRS E3",
  "ESRS E4",
  "ESRS E5",
  "ESRS S1",
  "ESRS S2",
  "ESRS S3",
  "ESRS S4",
  "ESRS G1",
];

const Guideline = () => {
  const [state, setState] = useState({
    activeRootNode: parentNodes[0],
    isNetworkDataLoading: false,
    allNetworkData: {},
    activeNodeData: [],
  });

  // ALL network data
  const handleFetchAllNetworkData = (activeNode) => {
    setState((prev) => ({
      ...prev,
      isNetworkDataLoading: true,
    }));

    //console.log("payload", payload);
    axios({
      method: "GET",
      url: Api.csrdGuidelineCCF,
    })
      .then((res) => {
        const { status, data } = res;
        console.log("Fetch ALL network data RES ---> ", data);
        if (status === 200) {
          if (Object.keys(data).length) {
            const rootNodes = Object.keys(data);
            if (rootNodes.length) {
              let tempObj = {};
              rootNodes.map((root, index) => {
                const nodesList = data[root]["nodes"]
                  ? data[root]["nodes"]
                  : [];
                tempObj[root] = nodesList;
              });

              setState((prev) => ({
                ...prev,
                isNetworkDataLoading: false,
                allNetworkData: { ...tempObj },
                activeNodeData: tempObj[activeNode],
              }));
            }
          } else {
            setState((prev) => ({
              ...prev,
              isNetworkDataLoading: false,
              allNetworkData: {},
              activeNodeData: [],
            }));
          }
        }
      })
      .catch((error) => {
        setState((prev) => ({
          ...prev,
          isNetworkDataLoading: false,
          allNetworkData: {},
        }));
        console.log("Fetch network data ERROR ---> ", error);
      });
  };

  const handleRootChange = (value) => {
    const data = state.allNetworkData ? state.allNetworkData[value] : [];

    setState((prev) => ({
      ...prev,
      activeRootNode: value,
      activeNodeData: [],
      isNetworkDataLoading: true,
    }));

    setTimeout(() => {
      setState((prev) => ({
        ...prev,
        isNetworkDataLoading: false,
        activeNodeData: data,
      }));
    }, 500);
  };

  useEffect(() => {
    handleFetchAllNetworkData(state.activeRootNode);
  }, []);

  return (
    <Page
      title={"CSRD Guideline Overview"}
      style={{ backgroundColor: "#222222", minHeight: "100vh" }}
    >
      <Container
        maxWidth="xl"
        sx={{ paddingTop: "30px", paddingBottom: "30px" }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} textAlign="center">
            <ButtonBase
              sx={{ marginBottom: "25px" }}
              disableRipple
              // onClick={() => openInNewTab("https://www.ongil.ai/")}
            >
              <img
                src={"https://www.ongil.ai/images/ongil-white-2x.png"}
                alt={"Ongil"}
                style={{ width: "215px" }}
              />
            </ButtonBase>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={8}>
                <Typography
                  sx={{ paddingLeft: "15px", paddingRight: "15px" }}
                  color="white"
                  variant="h3"
                >
                  CSRD Guideline Overview
                </Typography>
              </Grid>
              <Grid item xs={4} textAlign="right">
                <CustomAutoComplete
                  disableClearable
                  isLoading={false}
                  sx={{
                    width: "250px",
                    backgroundColor: "#ffffff",
                    borderRadius: "4px",
                    marginLeft: "auto",
                  }}
                  attributeName={"ESRS standards"}
                  options={parentNodes || []}
                  value={state.activeRootNode || ""}
                  getOptionLabel={(option) => option || ""}
                  onChange={(event, newValue) => {
                    handleRootChange(newValue);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <MainCard
              cardTitle={""}
              contentHeight={650}
              isLoadingSpin={false}
              sx={{
                backgroundColor: "#ffffff",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                border: "1px solid #ececec",
              }}
            >
              {state.isNetworkDataLoading ? (
                <CircularProgress
                  size={30}
                  sx={{ display: "block", margin: "0 auto" }}
                />
              ) : state.activeNodeData &&
                validateObjectData(state.activeNodeData) ? (
                <>
                  {/* <Typography variant="h4">
                    {convertToLabel(parentNodes[0])}
                  </Typography> */}

                  {/* Tree Graph */}
                  <TreeGraphHC
                    data={state.activeNodeData}
                    rootNode={state.activeRootNode}
                    unitMeasurement={""}
                    isCollapsedDefault={true}
                    isExpandOneNodeAtTime={true}
                    markerRadius={{ parent: 15, child: 15 }}
                  />
                </>
              ) : null}
            </MainCard>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Guideline;
