export default {
  // Auth endpoints
  // login: `/base/users/login/`,
  // logout: `/base/users/logout/`,
  // profile: "/base/users/profile/",
  // forgotPassword: "/base/v1/user/forgot-password/",
  // resetPassword: "/base/v1/user/reset-password/",

  // APIs Full URL
  pcfGraph: "https://common-api-server.ongil.ai/api/esg/graph/",
  pcfGraphUpdated: "https://common-api-server.ongil.ai/api/esg/graph_v1/",
  ccfGraph: "https://common-api-server.ongil.ai/api/esg/ccf/",
  pcfGraphCOC: "https://common-api-server.ongil.ai/api/esg/coca-cola/pcf/",
  ccfGraphCOC: "https://common-api-server.ongil.ai/api/esg/coca-cola/ccf/",
  pcfGraphCoffeeCOC:
    "https://common-api-server.ongil.ai/api/esg/coca-cola-coffee/pcf/",

  csrdGuidelineCCF: "https://common-api-server.ongil.ai/api/esg/csrd/ccf/",

  // Other Apis
  // pcfGraph: "/api/esg/graph/",
  // pcfGraphUpdated: "/api/esg/graph_v1/",
  // ccfGraph: "/api/esg/ccf/",
  // pcfGraphCOC: "/api/esg/coca-cola/pcf/",
  // ccfGraphCOC: "/api/esg/coca-cola/ccf/",
  // pcfGraphCoffeeCOC: "/api/esg/coca-cola-coffee/pcf/"
};
